<template>
  <base-label
    :legend="placeholder ?? ''"
    :labelType="labelType"
    :errors="errors"
    :is-active="isOpen"
    :is-disabled="isDisabled"
    :has-value="!isNotSet"
    :is-loading="isLoading || isInternalLoading || isLoadingOptions"
    :disable-disabled-opacity="disableDisabledOpacity"
    :icon="icon"
    :feedback="feedback"
    @click="activate"
    @keydown.enter="activate"
    @focus="
      () => {
        if (activateOnFocus) {
          activate();
        }
      }
    "
    ref="label"
  >
    <div class="input-select" v-class-mod:input-select="{ isOpen, isDisabled }">
      <div class="input-select__input-column">
        <span
          v-if="hasInputField && isOpen"
          class="input-select__input-wrapper"
          @click="setSelection"
          @keydown="isTyped = true"
          @keydown.enter="enterKeyHandle"
        >
          <input
            :autofocus="autofocus"
            class="input-select__input"
            @keydown.esc="blur"
            ref="input"
            v-model="searchString"
          />
        </span>
        <span v-else-if="isNotSet" class="input-select__not-specified">
          <cut-text :text="placeholder || translations.inputs.notSpecified" />
        </span>
        <span v-else class="input-select__dispay-value">
          <cut-text :text="displayValue" />
        </span>
        <span v-if="fitWidthToOptions" class="input-select__fit-width">
          <span v-if="isNotSet">{{ placeholder || translations.inputs.notSpecified }}</span>
          <span v-else>{{ displayValue }}</span>
          <span v-for="(option, key) in availableOptions" :key="key">
            <span v-if="'header' in option">{{ option.header }}</span>
            <span v-else-if="'title' in option">{{ option.title }}</span>
            <span v-if="'explanation' in option">{{ option.explanation }}</span>
          </span>
        </span>
      </div>
      <span class="input-select__spacer" />
      <icon-toggle-button
        v-if="canClear && !isDisabled && !!selectedValues.length"
        :tooltip="{ title: translations.inputs.clear }"
        :action="
          (e) => {
            e.stopPropagation();
            clear();
          }
        "
        icon="close"
        button-size="medium"
        tabindex="0"
        class="input-select__clear"
      />
    </div>
    <AppTeleport v-if="!isDisabled && label?.$el && isOpen && availableOptions">
      <div
        class="input-select__options"
        ref="modal"
        v-class-mod:input-select__options="[{ isSingleValue: !allowMultiple }, size]"
        v-focus-navigate="{
          exitOnEsc: true,
          exitOnTop: hasInputField,
          exitCallback: () => {
            if (!hasInputField) {
              close();
            }
          },
        }"
        v-stick-to="{
          align: 'right',
          stick: 'left',
          placement: 'bottom',
          stickTo: label,
          element: modal,
          disapaired: () => (isOpen = false),
        }"
        v-click-outside="{
          callback: () => (isOpen = false),
          allowedNodes: [label?.$el],
        }"
      >
        <template v-if="hasNewOptionToAdd">
          <span class="input-select__info-text" tabindex="0" @click="addNew" @keydown.enter="addNew">
            {{ translations.inputs.select.add }}
          </span>
          <span class="input-select__seperator" />
        </template>
        <span
          v-if="(!!availableOptions.length && !filteredOptions.length && !!searchMap) || !availableOptions.length"
          class="input-select__info-text"
        >
          {{ translations.inputs.select.noMatches }}
        </span>
        <template v-if="hasDeselectAll">
          <span class="input-select__deselect-all" tabindex="0" @click="clear" @keydown.enter="clear">
            <span class="input-select__check input-select__check--isIndeterminate" />
            {{ translations.inputs.deselect }}
          </span>
          <div v-if="allowMultiple">
            <template v-for="(option, optionKey) in availableOptions">
              <span
                v-if="isSelected(option)"
                class="input-select__option"
                tabindex="0"
                @click="toggle(option)"
                @keydown.enter="toggle(option)"
                :key="optionKey"
                v-class-mod:input-select__option="{
                  isSelected: true,
                  isDisabled: isDisabled || option?.disabled,
                }"
              >
                <span v-if="allowMultiple" class="input-select__check" />
                <icon-wrapper
                  v-if="option?.disabled"
                  :icon="option.disabled.icon"
                  :tooltip="option.disabled.tooltip"
                  color="black-50"
                  class="input-select__disabled-info"
                />
                <cut-text :text="listMap(option)" class="input-select__value" />
                <span v-if="option?.explanation" class="input-select__explanation">
                  <cut-text :text="option.explanation" />
                </span>
              </span>
            </template>
          </div>
          <span class="input-select__seperator" />
        </template>
        <template v-for="(group, groupKey) in filteredOptions">
          <header v-if="group.heading" class="input-select__group-heading" :key="groupKey">
            {{ group.heading }}
          </header>
          <template v-for="(option, optionKey) in group.options" :key="optionKey">
            <span
              v-if="!allowMultiple || (allowMultiple && !isSelected(option))"
              class="input-select__option"
              tabindex="0"
              @click="toggle(option)"
              @keydown.enter="toggle(option)"
              v-class-mod:input-select__option="{
                isSelected: isSelected(option),
                isDisabled: isDisabled || option?.disabled,
              }"
            >
              <span v-if="allowMultiple" class="input-select__check" />
              <icon-wrapper
                v-if="option?.disabled"
                :icon="option.disabled.icon"
                :tooltip="option.disabled.tooltip"
                color="black-50"
                class="input-select__disabled-info"
              />
              <cut-text :text="listMap(option)" class="input-select__value" />
              <span v-if="option?.explanation" class="input-select__explanation">
                <cut-text :text="option.explanation" />
              </span>
            </span>
          </template>
        </template>
      </div>
    </AppTeleport>
  </base-label>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch, withDefaults } from "vue";

import { AppTeleport } from "@horizon56/bootstrap";
import { vClickOutside } from "@horizon56/directives/click-outside";
import { vFocusNavigate } from "@horizon56/directives/focus-navigate";
import { vStickTo } from "@horizon56/directives/stick-to";
import { IconName } from "@horizon56/fonts/types";
import { InputModalSize } from "@horizon56/styles/types";
import { delayFn, listDividing, searchList } from "@horizon56/utils";

import { translations } from "@/infrastructure/translations";

import IconToggleButton from "@/components/buttons/icon-toggle-button.vue";
import CutText from "@/components/content/cut-text.vue";
import IconWrapper from "@/components/icons/icon-wrapper.vue";
import BaseLabel, { PublicProps } from "@/components/inputs/base-label.vue";
import { FeedbackReturnType } from "@/components/inputs/input-feedback.vue";
import { TooltipProps } from "@/components/tooltips/tool-tip.vue";

export type Option<T = any> = {
  id: string;
  title: string;
  explanation?: string;
  header?: string;
  disabled?: { icon: IconName; tooltip: TooltipProps };
  [key: PropertyKey]: unknown;
} & T;

type SelectOptions = Group[];

type Group = { heading?: string; options: Option[] };

export type InputSelectProps = PublicProps & {
  options: Option[] | (() => Promise<Option[]>) | Promise<Option[]> | (() => Option[]);

  allowDeselectAll?: boolean;
  allowMultiple?: boolean;
  allowNewOption?: boolean;
  activateOnFocus?: boolean;
  autofocus?: boolean;
  canClear?: boolean;
  selectedValue?: string;
  fitWidthToOptions?: boolean;
  placeholder?: string;
  displayMap?: (o: Option[]) => string;
  isDisabled?: boolean;
  isSelected?: (o: Option) => boolean;
  listMap?: (o: Option) => string;
  searchMap?: (o: Option) => string;
  update?: (o: Option[]) => FeedbackReturnType;
  size?: InputModalSize;
};

const props = withDefaults(defineProps<InputSelectProps>(), {
  displayMap: (o: Option[]) => o.map((o) => o.title).join(", "),
  isSelected: (_o: Option) => false,
  listMap: (o: Option) => o.title,
  activateOnFocus: true,
  size: "large",
});

const emit = defineEmits<{
  (e: "update", a: Option[]): void;
  (e: "new-option", a: string): void;
  (e: "is-active", a: boolean): void;
}>();

const label = ref<InstanceType<typeof BaseLabel>>();
const modal = ref<HTMLElement>();
const input = ref<HTMLInputElement>();
const feedback = ref<PublicProps["feedback"]>();

const isOpen = ref(false);
const hasFocus = ref(false);
const isActivating = ref(false);
const isInternalLoading = ref(false);
const searchString = ref<string>("");
const isTyped = ref(false);
const availableOptions = ref<Option[]>();
const isLoadingOptions = ref(false);

const isNotSet = computed(() => !props.selectedValue && !selectedValues.value?.length);
const selectedValues = computed(() => availableOptions.value?.filter(props.isSelected) ?? []);
const displayValue = computed(() => props.selectedValue || props.displayMap(selectedValues.value));
const hasInputField = computed(() => !!props.searchMap || hasNewOptionToAdd.value);
const hasNewOptionToAdd = computed(
  () =>
    props.allowNewOption &&
    isTyped.value &&
    searchString.value !== "" &&
    searchString.value !== displayValue.value &&
    !availableOptions.value?.some((o) => props.listMap(o) === searchString.value),
);

const hasDeselectAll = computed(
  () => props.allowDeselectAll && availableOptions.value?.some((s) => !s?.disabled && props.isSelected(s)),
);
const filteredOptions = computed((): SelectOptions => {
  const options =
    props.searchMap && searchString.value !== displayValue.value
      ? searchList(searchString.value, availableOptions.value || [], props.searchMap)
      : availableOptions.value ?? [];
  const groups = listDividing(options, (o) => o?.header);
  return groups.map(({ key, items }) => ({ heading: key, options: items }));
});

const toggle = (option: Option) => {
  if (option.disabled) {
    return;
  }
  const isSelected = props.isSelected(option);
  if (!props.allowMultiple) {
    updateValue(isSelected ? [] : [option]);
    label.value?.$el?.focus();
    isOpen.value = false;
  } else {
    updateValue(isSelected ? selectedValues.value.filter((o) => o !== option) : [...selectedValues.value, option]);
  }
};

const enterKeyHandle = () => window.requestAnimationFrame(addNew);

const blurEvent = () => {
  if (isOpen.value) {
    close();
  }
};

const focusEvent = () => {
  if (!isOpen.value) {
    return;
  }
  if (document.activeElement && document.activeElement !== document.body && !isElemWithin(document.activeElement)) {
    close();
  }
};
const blur = () => {
  input.value?.blur();
  close();
};

const activate = () => {
  if (isActivating.value || isOpen.value || props.isDisabled) {
    return;
  }
  window.requestAnimationFrame(() => {
    isActivating.value = true;
    window.requestAnimationFrame(async () => {
      if (!isOpen.value && !props.isDisabled) {
        window.dispatchEvent(new Event("input-select-focus"));
        isOpen.value = true;
        searchString.value = displayValue.value;
        isTyped.value = false;
        if (typeof props.searchMap === "function") {
          await setSelection();
        }
      } else if (isOpen.value) {
        if (!isElemWithin(document.activeElement)) {
          isOpen.value = false;
          isTyped.value = false;
        }
      }
      isActivating.value = false;
    });
  });
};

const setSelection = async () => {
  await delayFn(() => !!input.value);
  input.value?.focus();
  input.value?.select();
};

const updateValue = async (options: Option[]) => {
  emit("update", options);
  if (typeof props.update === "function") {
    isInternalLoading.value = true;
    const result = await props.update(options);
    if (result && "status" in result) {
      feedback.value = {
        ...result,
        callback: () => {
          feedback.value = undefined;
          result.callback?.();
        },
      };
    }
    isInternalLoading.value = false;
  }
};

const addNew = () => {
  if (hasNewOptionToAdd.value) {
    emit("new-option", searchString.value);
    if (!props.allowMultiple) {
      blur();
    }
  }
};

const clear = () => {
  window.requestAnimationFrame(() => {
    updateValue(selectedValues.value.filter((s) => s.disabled));
  });
};

const close = () => {
  window.requestAnimationFrame(() => {
    if (input.value !== document.activeElement) {
      isOpen.value = false;
    }
  });
};

const isElemWithin = (elem: Element | null) => {
  const container = label.value?.$el;
  const modalElem = modal.value;

  return container === elem || !!container?.contains(elem) || modalElem === elem || !!modalElem?.contains(elem);
};

watch(
  () => displayValue.value,
  () => {
    if (isTyped.value) {
      return;
    }
    searchString.value = displayValue.value;
  },
);

watch(
  () => isOpen.value || hasFocus.value,
  (is) => emit("is-active", is),
);

watch(
  () => isOpen.value || hasFocus.value,
  async (is) => {
    if (Array.isArray(props.options)) {
      availableOptions.value = props.options;
    } else if (!!is && typeof props.options === "function") {
      isLoadingOptions.value = true;
      availableOptions.value = await props.options();
      isLoadingOptions.value = false;
    }
  },
  { immediate: true },
);

onMounted(() => {
  window.addEventListener("input-select-focus", blurEvent);
  window.addEventListener("focus", focusEvent, { capture: true });
  if (props.autofocus) {
    activate();
  }
});
</script>

<style lang="scss" scoped>
@import "./mixins";
.input-select {
  $block: &;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  outline: none;
  height: 100%;

  @include icon-after($icon-arrow_drop_down) {
    font-size: var(--app-icon-size-large);
    color: var(--black-50);
  }
  &:hover:after,
  &--isOpen:after {
    color: var(--black-90);
  }
  &--isOpen:after {
    transform: rotate(-180deg);
  }
  &__not-specified {
    user-select: none;
    color: var(--black-50);
  }
  &__input-wrapper {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
  &__input-column {
    width: 100%;
    display: flex;
    flex-flow: column;
  }
  &__input {
    flex-shrink: 1;
    width: 100%;
    @include input-reset;
    line-height: 1.28;
    font-size: var(--app-font-size-base);
  }
  &__options {
    display: flex;
    flex-flow: column;
    border-radius: var(--app-radius-medium);
    border: 1px solid var(--black-20);
    background: var(--menu-bg);
    padding: 0;
    position: fixed;
    overflow: auto;
    @include sticky-content;
    @include set-scrollbar();
    &:has(#{$block}__group-heading) {
      padding-top: 0;
      padding-bottom: 0;
    }

    @each $size in $inputModalSizes {
      &--#{$size} {
        width: var(--app-input-modal-size-#{$size});
      }
    }
  }
  &__fit-width *,
  &__fit-width {
    display: inline-flex;
    flex-flow: column;
    height: 0px;
    overflow: hidden;
  }

  &__deselect-all,
  &__info-text,
  &__option,
  &__dispay-value {
    user-select: none;
  }
  &__deselect-all,
  &__info-text,
  &__option {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    user-select: none;
    padding: var(--app-spacing-size-medium);
    cursor: pointer;
    height: var(--app-button-height-large);
    @include focus-outline;
  }
  &__info-text {
    color: var(--black-50);
    justify-content: center;
  }
  &__disabled-info {
    margin-right: 8px;
  }
  &__seperator {
    display: block;
    border-bottom: 1px solid var(--black-10);
  }
  &__group-heading {
    height: var(--app-button-height-medium);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    user-select: none;
    background: var(--menu-hover);
    color: var(--black-50);
    padding: var(--app-spacing-size-medium);
    &:not(:first-child) {
      border-top: 1px solid var(--black-10);
    }

    &:first-child {
      border-top-left-radius: var(--app-radius-medium);
      border-top-right-radius: var(--app-radius-medium);
    }
    &:last-child {
      border-bottom-left-radius: var(--app-radius-medium);
      border-bottom-right-radius: var(--app-radius-medium);
    }
  }
  &__value:not(:last-child) {
    margin-right: var(--app-spacing-size-small);
  }
  &__explanation {
    color: var(--black-50);
    margin-left: auto;
  }
  &__check {
    margin-right: 8px;
    color: var(--black-50);
    font-size: var(--app-icon-size-medium);
    @include focus-outline;
    @include icon-button();
    @include icon-after($icon-check_box_outline_blank);

    &--isIndeterminate {
      @include icon-after($icon-indeterminate_check_box);
    }
  }
  &__option--isSelected #{&}__check {
    color: var(--black-90);
    @include icon-after($icon-check_box);
  }
  &__options--isSingleValue #{&}__option {
    &:hover {
      background: var(--menu-hover);
    }
    &--isSelected {
      background: var(--menu-active);
    }
  }
  &__option:not(#{&}__option--isDisabled):hover #{&}__check {
    @include icon-button-hover();
  }
  &__deselect-all:hover #{&}__check {
    @include icon-button-hover();
  }
  &__option--isDisabled #{&}__check {
    color: var(--black-28);
    pointer-events: none;
  }
  &__spacer {
    margin-left: auto;
  }
  &__clear {
    margin-left: 10px;
  }
  &--isDisabled:after {
    content: unset;
    display: none;
  }
}
</style>
